<template>
  <div v-if="productInfo" class="product">
    <!-- 导航 -->
    <div class="product-nav" ref="nav">
      <van-icon class="back" color="#fff" name="arrow-left" @click="onBack" ref="navBack" />
      <div class="nav-title" ref="navTitle">
        <div class="product-anchor">
          <a class="anchor-item" :class="currentAnchor == 1 ? 'cur' : ''" @click="goAnchor('#goodsAnchor', 1)">商品</a>
          <a class="anchor-item" :class="currentAnchor == 2 ? 'cur' : ''" @click="goAnchor('#detailAnchor', 3)">详情</a>
          <a class="anchor-item" :class="currentAnchor == 3 ? 'cur' : ''" @click="goAnchor('#rateAnchor', 2)">评价</a>
          <a class="anchor-item" :class="currentAnchor == 4 ? 'cur' : ''" @click="goAnchor('#recommendAnchor', 4)">推荐</a>
        </div>
      </div>
    </div>
    <!-- 商品 -->
    <div id="goodsAnchor">
      <!-- 产品图 -->
      <div class="product-swiper">
        <van-swipe width="100%" :loop="false">
          <van-swipe-item v-if="video.length > 0" style="position: relative; background-color: #000;">
            <video
              id="video"
              ref="productVideo"
              :src="video"
              preload="auto"
              :poster="video + '?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast'"
            >
            </video>
            <div class="swiper-play" @click="onPlayVideo">
              <van-icon v-if="playing" name="pause-circle-o" color="#fff" size="55" />
              <van-icon v-else name="play-circle-o" color="#fff" size="55" />
            </div>
          </van-swipe-item>
          <van-swipe-item v-for="(item, index) in images " :key="index" @click="onTapSwipe(index)">
            <img v-lazy="item" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="buy-area">
        <div class="price-wrap">
          <span v-if="hasPrice" class="price">
            ￥<span class="price-num">{{ productInfo.price }}</span>
          </span>
          <span v-else class="price">
            <span class="price-num">买方报价</span>
          </span>
        </div>
        <div class="goods-name">
          <!-- 激励宝产品 -->
          <span v-if="productInfo.commission > 0" class="tag">激励宝</span>
          {{ productInfo.title }}
        </div>
        <div class="goods-desc">
          <span>{{pDesc}}</span>
          <span class="stock">库存：{{ productInfo.stockNum || 0 }}</span>
        </div>
        <div class="goods-desc"><van-icon name="location" />所在地：{{ location }}</div>
      </div>
      <!-- 激励宝附加服务 -->
      <div v-if="productInfo.commission > 0 || productInfo.isFreight == 1 || productInfo.isInstall == 1 || (productInfo.condition == 99 && productInfo.isUnload == 1)" class="goods-section">
        <!-- 激励宝优惠 -->
        <div v-if="productInfo.commission > 0" class="goods-discount">
          <span class="title">优惠</span>
          <div class="content">
            <div class="content-item">
              <span>激励宝</span>
              <span v-if="productInfo.commission == 1">激励金：成交金额的{{productInfo.commissionPercent}}%</span>
              <span v-if="productInfo.commission == 2">激励金：{{productInfo.commissionPrice}}元</span>
            </div>
          </div>
        </div>
        <!-- 附加服务 -->
        <div class="goods-serve">
          <ul class="detail-serve">
            <li v-if="productInfo.isFreight == 1" class="detail-serve-item">
              <van-icon name="certificate" color="#ff0069" />
              <span>免费运输</span>
            </li>
            <li v-if="productInfo.isInstall == 1" class="detail-serve-item">
              <van-icon name="certificate" color="#ff0069" />
              <span>免费安装</span>
            </li>
            <li v-if="productInfo.condition == 99 && productInfo.isUnload == 1" class="detail-serve-item">
              <van-icon name="certificate" color="#ff0069" />
              <span>设备已拆除</span>
            </li>
          </ul>
        </div>
      </div>
      <div id="detailAnchor"></div>
    </div>
    <!-- 详情 -->
    <div class="goods-section">
      <van-tabs v-model="detailActive" color="#ff0069">
        <van-tab title="商品介绍" name="intro">
          <div class="tab-subpage">
            <h4 class="subpage-tit">商品资料</h4>
            <div>
              <div v-for="(item,index) in originImages" :key="index" class="goods-cert-item" @click="onTapCert(item)"><img v-lazy="item" /></div>
              <div v-for="(cert) in certImages" :key="cert.name" class="goods-cert-item" @click="onTapCert(cert.url)"><img v-lazy="cert.url" /></div>
            </div>
            <h4 class="subpage-tit">商品描述</h4>
            <div class="goods-intro-content" v-html="productInfo.content"></div>
          </div>
        </van-tab>
        <van-tab title="规格参数" name="params">
          <div class="tab-subpage">
            <h4 class="subpage-tit">商品参数</h4>
            <table class="product-params-table">
              <tbody>
                <tr v-for="(para, index) in pList" :key="index">
                  <td>{{ para.name }}</td>
                  <td>{{ para.value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </van-tab>
      </van-tabs>
      <div id="rateAnchor"></div>
    </div>
    <!-- 评价问答 -->
    <div class="goods-section">
      <GoodsRate :productInfo="productInfo" />
      <GoodsQuestion :productInfo="productInfo" />
      <div id="recommendAnchor"></div>
    </div>
    <!-- 推荐 -->
    <div class="goods-section">
      <div class="goods-section-title">
        <h3>猜你喜欢</h3>
      </div>
      <div class="goods-recommend">
        <ul>
          <li v-for="rc in rcList" :key="rc.productId" @click="onClickRcItem(rc.productId)">
            <div class="rc-item">
              <div class="img-wrap"><img :src="rc.titleImg" /></div>
              <div class="name">{{ rc.title }}</div>
              <div v-if="!!rc.price && parseInt(rc.price) != 0" class="buy-wrap">
                ￥<span class="num">{{rc.price}}</span>
              </div>
              <div v-else class="buy-wrap">买方报价</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="page-footer">
      <div class="footer-logo"><img src="../assets/headerLogo.png" /></div>
    </div>
    <!-- 底部bar -->
    <van-goods-action>
      <van-goods-action-icon icon="shop-o" text="店铺" :url="'https://etb618.com/store?id=' + productInfo.storeId" />
      <van-goods-action-icon v-if="statusInfo.isFavorites == 1" icon="star" text="已收藏" color="#ff5000" @click="onCollect" />
      <van-goods-action-icon v-else icon="star-o" text="收藏" @click="onCollect" />
      <van-goods-action-icon icon="cart-o" text="购物车" to="/cart" />
      <van-goods-action-button type="warning" text="加入购物车" @click="onAddCart" />
      <van-goods-action-button v-if="hasPrice" type="danger" text="立即购买" @click="onBuy" />
      <van-goods-action-button v-else type="danger" text="立即报价" @click="onQuote" />
    </van-goods-action>
    <van-action-sheet v-model="showQuote" title="意向报价">
      <div class="intention-content">
        <van-form @submit="onSubmit" validate-trigger="onSubmit">
          <van-field
            v-model="quote.amount"
            name="amount"
            label="购买数量"
            placeholder="请输入"
            type="digit"
            :rules="[{ required: true, message: '请输入购买数量' }]"
          />
          <van-field
            v-model="quote.quote"
            name="quote"
            label="总报价(元)"
            placeholder="请输入"
            type="digit"
            :rules="[{ required: true, message: '请输入总报价' }]"
          />
          <van-button type="info" native-type="submit" block round>提交</van-button>
        </van-form>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import utils from '@/common/utils';
import User from '@/common/user';
import moment from 'moment';
import Fetch from '@/common/fetch';
import { GetSellProductDetail, GetProductStatus, StoreProductSearch, CollectProduct, AddToCart, AddBuyIntention } from '@/common/interface';
import { INFO_OPTIONS } from '@/common/const';

import GoodsRate from '@/components/GoodsRate.vue';
import GoodsQuestion from '@/components/GoodsQuestion.vue';
import Vue from 'vue';
import { GoodsAction, GoodsActionIcon, GoodsActionButton, Dialog } from 'vant';
import { ImagePreview, Toast, ActionSheet, Form, Field } from 'vant';

Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(ActionSheet);
Vue.use(Form);
Vue.use(Field);
Vue.use(Toast);

export default {
  name: "Product",
  components: { GoodsRate, GoodsQuestion },
  data() {
    return {
      userInfo: User.getInfo(),
      productInfo: null,
      statusInfo: {},
      pDesc: '',  // 商品描述
      location: '', // 所在地
      pList: [],  // 格式化的商品参数
      rcList: [],   // 商品推荐列表
      playing: false, // 是否在播放视频
      currentAnchor: 1,
      detailActive: 'intro', // 详情选中标签
      showQuote: false,
      quote: {
        amount: '',
        quote: '',
      },
    };
  },
  computed: {
    // 商品图片
    images () { 
      let images = [];
      if (this.productInfo.mainImgs && this.productInfo.mainImgs.length > 0) {
        images = this.productInfo.mainImgs?.split(';') || [];
      }
      return images;
    },
    // 商品原图
    originImages() {  
      return this.images.map(utils.getOriginalUrl) || [];
    },
    // 产品视频 
    video() {    
      let video = '';  
      if (this.productInfo.videos && this.productInfo.videos.length > 0) {
        const videos = this.productInfo.videos?.split(';') || [];
        video = videos[0];
      }
      return video;
    },
    // 商品证书图片
    certImages() {
      let certImages = [];
      let reportList = [];
      switch (parseInt(this.productInfo.productType)) {
        case 1:
          reportList = INFO_OPTIONS.boiler_report_list;
          break;
        case 2:
          reportList = INFO_OPTIONS.burner_report_list;
          break;
        case 3:
          reportList = INFO_OPTIONS.auxiliary_report_list;
          break;
        default:
          break;
      }
      reportList.map((item) => {
        if (this.productInfo[item.name]) {
          certImages.push({
            name: item.label,
            url: this.productInfo[item.name] || '',
          })
        }
      });
      return certImages;
    },
    hasPrice () {
      return !!this.productInfo.price && parseInt(this.productInfo.price) != 0;
    },
  },
  watch: {
    $route() {
      // route更新，刷新页面
      this.fetchPageInfo();
    }
  },
  created() {
    this.fetchPageInfo();
  },

  mounted() {
    window.addEventListener("scroll",this.handleScroll); 
  },

  destroyed() {
    document.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    handleScroll() {
      const docScroll = document.documentElement.scrollTop;
      // 导航栏动效
      if (!this.$refs.nav) {
        return;
      }

      if (docScroll <= 0) {
        this.$refs.nav.style.background = 'rgba(255,255,255,0)';
        this.$refs.navBack.style.background = 'rgba(0,0,0,0.5)';
        this.$refs.navBack.style.color = '#fff';
        this.$refs.navTitle.style.opacity = 0;
      } else if (docScroll  > 100) {
        this.$refs.nav.style.background = 'rgba(255,255,255,1)';
        this.$refs.navBack.style.background = 'rgba(0,0,0,0)';
        this.$refs.navBack.style.color = '#222';
        this.$refs.navTitle.style.opacity = 1;
      } else {
        const scale = docScroll / 100;
        this.$refs.nav.style.background = `rgba(255,255,255,${scale})`;
        this.$refs.navBack.style.background = `rgba(0,0,0,${0.5 - scale / 2})`;
        this.$refs.navTitle.style.opacity = scale;
      }

      const scrollTop1 = document.getElementById('goodsAnchor').offsetTop - docScroll;
      const scrollTop2 = document.getElementById('detailAnchor').offsetTop - docScroll;
      const scrollTop3 = document.getElementById('rateAnchor').offsetTop + document.getElementById('rateAnchor').offsetParent.offsetTop - docScroll;

      if (scrollTop3 <= 0) {
        this.currentAnchor = 3
      } else if (scrollTop2 <= 0) {
        this.currentAnchor = 2;
      } else if (scrollTop1 <= 0) {
        this.currentAnchor = 1;
      }
      
    },

    onBack() {
      this.$router.go(-1);
    },

    onExitFullScreen() {
      const movie = this.$refs.productVideo;
      movie.pause();
      this.playing = false;
    },

    onPlayVideo() {
      const movie = this.$refs.productVideo;
      if (this.playing) {
        movie.pause();
        this.playing = false;
      } else {
        movie.play();
        this.playing = true;
        if (movie.requestFullscreen) { 
          movie.requestFullscreen(); 
        } else if (movie.mozRequestFullScreen) { 
          movie.mozRequestFullScreen(); 
        } else if (movie.webkitRequestFullScreen) { 
          movie.webkitRequestFullScreen(); 
        } else if (movie.msRequestFullscreen) { 
          movie.msRequestFullscreen(); 
        }

        movie.addEventListener('webkitendfullscreen', this.onExitFullScreen);
        movie.addEventListener('x5videoexitfullscreen', this.onExitFullScreen);
        movie.addEventListener('ended', this.onExitFullScreen);
      }
    },
    
    fetchPageInfo () {
      this.fetchDetailData();
      this.fetchStatusData();
    },

    // 商品详细信息
    fetchDetailData() {
      const id = utils.getQueryString('id') || '';
      const sign = utils.getQueryString('sign') || '';
      if (id) {
        const params = {
          productId: id,
          sign,
        };
        Fetch.post(GetSellProductDetail, params).then((res) => {
          if (res) {
            this.productInfo = res; 
            window.scrollTo(0, 0);
            this.fetchStoreRecommends();
            setTimeout(() => {
              this.formatInfo();
            }, 0);
          }
        }).catch(() => {
          this.productInfo = null;
        });
      }
    },

    // 商品收藏，报价相关状态信息
    fetchStatusData() {
      if (!this.userInfo) {
        return;
      }
      const id = utils.getQueryString('id') || '';
      const params = {
        saleType: 1,
        productId: id,
      };
      Fetch.post(GetProductStatus, params).then((res) => {
        this.statusInfo = res;
      }).catch(()=>{});
    },

    // 商品推荐店铺推荐
    fetchStoreRecommends() {
      if (this.productInfo) {
        const params = {
          storeId: this.productInfo.storeId,
          pageNum: 1,
          pageSize: 9,
        };
        Fetch.post(StoreProductSearch, params).then((res) => {
          this.rcList = res.list || [];
        }).catch(() => {
        });
      }
    },

    // 格式化商品参数
    formatInfo() {
      const d = this.productInfo;
      // 所在地
      this.location = utils.getCityName(d.location) || '-';

      // 商品描述
      let titleList = [];
      if (d.attribute) {
        titleList.push(utils.getFormItemValue('attribute', d));
      }
      if (d.attribute == 1 && d.tonnage) {
        titleList.push(utils.getFormItemValue('tonnage', d));
      }
      if (d.attribute == 2 && d.power) {
        titleList.push(utils.getFormItemValue('power', d));
      }
      if (d.emission == 1 || d.emission == 2) {
        titleList.push('低氮');
      }
      if (d.productType == 3 && d.auxCategory) {
        titleList.push(d.auxCategory);
      }
      if (d.condition) {
        titleList.push(utils.getFormItemValue('condition', d));
      }
      this.pDesc = titleList.join(' | ');

      // 商品参数
      let pList = [];
      pList.push({ name: '商品类型', value: d.productType == 1 ? '锅炉' : ( d.productType == 2 ? '燃烧机' : '辅机' ) })
      if (d.productType == 3) {
        pList.push({ name: '类目', value: d.auxCategory || '-' });
      }
      pList.push({ name: '品牌', value: d.brand || '-' });
      pList.push({ name: '型号', value: d.model || '-' });
      pList.push({ name: '成色', value: utils.getFormItemValue('condition', d) });
      if (d.condition == 99) {
        pList.push({ name: '设备拆除状态', value: d.isUnload == 1 ? '已拆除' : '未拆除' });
      }
      pList.push({ name: '产地', value: utils.getCityName(d.origin) });
      pList.push({ name: '生产情况', value: utils.getFormItemValue('produceState', d) });
      if (d.produceState == 1) {
        pList.push({ name: '生产日期', value: d.productDate ? moment(d.productDate).format('YYYY-MM') : '-' });
      } else if (d.produceState == 2) {
        pList.push({ name: '生产工期', value: d.producePeriod || '-' });
      }
      pList.push({ name: '所在地', value: utils.getCityName(d.location) });
      pList.push({ name: '运行情况', value: utils.getFormItemValue('runStatus', d) });
      pList.push({ name: '厂商名称', value: d.firmName || '-' });
      pList.push({ name: '厂商资质', value: utils.getFormItemValue('firmQualificate', d) });
      if (d.productType == 1) {
        pList.push({ name: '属性', value: utils.getFormItemValue('attribute', d) });
      }
      if (d.attribute == 1) {
        pList.push({ name: '吨位', value: utils.getFormItemValue('tonnage', d) });
      } else if (d.attribute == 2) {
        pList.push({ name: '功率', value: utils.getFormItemValue('power', d) });
      }
      if (d.productType == 1 && d.attribute == 2) {
        pList.push({ name: '进水温度', value: d.inWaterT || '-' + '℃' });
        pList.push({ name: '出水温度', value: d.outWaterT || '-' + '℃' });
      }
      if (d.productType <= 2) {
        pList.push({ name: '燃料', value: utils.getFuelValue(d) });
        pList.push({ name: '排放', value: utils.getFormItemValue('emission', d) });
      }
      if (d.productType == 1) {
        pList.push({ name: '压力', value: utils.getFormItemValue('pressure', d) });
        // 炉膛尺寸
        let diam = '-';
        if (d.furnaceSize && d.furnaceSize.split(';') && d.furnaceSize.split(';')[0]) {
          diam = `${d.furnaceSize.split(';')[0]}mm`;
        }
        let length = '-';
        if (d.furnaceSize && d.furnaceSize.split(';') && d.furnaceSize.split(';')[1]) {
          length = `${d.furnaceSize.split(';')[1]}mm`;
        }
        pList.push({ name: '炉膛直径', value: diam });
        pList.push({ name: '炉膛长度', value: length });
        pList.push({ name: '形式', value: utils.getFormItemValue('modality', d) });
        pList.push({ name: '结构', value: utils.getFormItemValue('boiler_structure', d, d.structure) });
        pList.push({ name: '综合热效率', value: d.thermalEfficiency > 0 ? `${d.thermalEfficiency}%` : '-' });
      }
      if (d.productType == 2) {
        pList.push({ name: '结构', value: utils.getFormItemValue('burner_structure', d, d.structure) });
      }
      pList.push({ name: '运费', value: d.isFreight === 1 ? '包含' : '不包含' });
      pList.push({ name: '安装', value: d.isInstall === 1 ? '包含' : '不包含' });
      pList.push({ name: '发票', value: d.isInvoice === 1 ? '包含' : '不包含' });
      pList.push({ name: '质保', value: d.isQa === 1 ? `${d.expDate}` : '不包含' });
      if (d.productType == 1) {
        pList.push({ name: '一次仪表/阀门', value: d.isInstrumentValve === 1 ? '包含' : '不包含' });
        pList.push({ name: '节能器', value: d.isEnergySaver === 1 ? '包含' : '不包含' });
        pList.push({ name: '控制柜', value: d.isControlCabinet === 1 ? '包含' : '不包含' });
        pList.push({ name: '辅机', value: d.isAuxiliary === 1 ? `${d.auxiliaryName}` : '不包含' });
        pList.push({ name: '含燃烧机', value: d.isBurner === 1 ? '包含' : '不包含' });
      }
      let customParamList = [];
      if (d.customParams) {
        customParamList = JSON.parse(d.customParams) || [];
      }
      customParamList.map(item => {
        pList.push({ name: item.name, value: item.value });
      })

      this.pList = pList;
    },

    verifyLogin() {
      if (!this.userInfo) {
        this.$router.push('/login');
        return false;
      }
      return true;
    },

    onTapSwipe(index) {
      ImagePreview({
        images: this.originImages,
        startPosition: index,
      });
    },

    onTapCert(image) {
      ImagePreview([image]);
    },

    onClickRcItem(productId) {
      if (productId != this.productInfo.productId) {
        this.$router.push('/product?id=' + productId);
      }
    },

    goAnchor(selector, index) {
      this.currentAnchor = index;
      this.$el.querySelector(selector).scrollIntoView()
    },

    onCollect() {
      if (this.verifyLogin()) {
        const params = {
          productId: this.productInfo.productId,
          saleType: 1,
          toggle: this.statusInfo.isFavorites == 1 ? 0 : 1,
        };
        Fetch.post(CollectProduct, params).then(() => {
          this.statusInfo.isFavorites = 1^this.statusInfo.isFavorites;
          console.log(this.statusInfo.isFavorites)
        }).catch((err) => {
          const { msg } = err;
          Toast(msg);
        });
      }
    },

    onTapStore() {
      window.location.href = 'https://etb618.com/store?id=' + this.productInfo.storeId;
    },

    onAddCart() {
      if (this.verifyLogin()) {
        if (this.hasPrice) {
          const params = {
            productType: this.productInfo.productType,
            productId: this.productInfo.productId,
          };
          Fetch.post(AddToCart, params).then(() => {
            Toast.success('添加至购物车成功');
          }).catch((err) => {
            Toast.fail(err.msg || '请求失败');
          });
        } else {
          Toast.fail('买方报价商品无法加入购物车');
        }
      }
    },

    onBuy() {
      Toast('请使用电脑端进行操作');
    },

    onQuote() {
      if (this.statusInfo.isIntention == 1) {
        Toast.fail('您已报价');
        return;
      }
      this.showQuote = true;
    },

    onSubmit(values) {
      if (values.amount <= 0 || values.amount > 99) {
        Toast.fail('请输入合适的购买数量');
        return;
      }
      if (values.amount > this.productInfo.stockNum) {
        Toast.fail('最多只能购买' + this.productInfo.stockNum + '个');
        return;
      }
      if (values.quote < 1 || values.quote > 9999999) {
        Toast.fail('请输入合适的报价');
        return;
      }
      // 提交请求
      const params = {
        productId: this.productInfo.productId,
        productType: this.productInfo.productType,
        price: values.quote,
        amount: values.amount
      };
      Fetch.post(AddBuyIntention, params).then(() => {
        Dialog({ message: '报价提交成功，等待卖家处理' });
        this.showQuote = false;
        this.fetchStatusData();
      }).catch((err) => {
        Toast.fail(err.msg || '请求失败');
      });
    },
  },

}
</script>

<style scoped>
body {
  background: #f2f2f2;
}
</style>

<style lang="scss">
.product {
  background: #f2f2f2;
  #goodsAnchor {
    position: relative;
  }
  #rateAnchor, #detailAnchor, #recommendAnchor {
    position: absolute;
    bottom: 45px;
    left: 0;
    opacity: .1;
  }
}
.product-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  height: 45px;
  background: rgba(0,0,0,0);
  .back {
    position: absolute;
    top: 7.5px;
    left: 10px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: rgba($color: #000, $alpha: 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav-title {
    margin: 5px 70px 0;
    height: 35px;
    font-size: 16px;
    line-height: 44px;
    text-align: center;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    opacity: 0;
    .product-anchor {
      height: 35px;
      line-height: 35px;
      font-size: 14px;
      display: flex;
      .anchor-item {
        position: relative;
        flex: 1;
        text-align: center;
      }
      .anchor-item.cur {
        font-weight: bold;
      }
      .anchor-item.cur::after {
        display: block;
        position: absolute;
        content: "";
        width: 28px;
        height: 3px;
        left: 50%;
        transform: translateX(-50%);
        background-image: linear-gradient(90deg,#ff0069,#fad1cb);
        bottom: 0;
      }
    }
  }
}
.product-swiper {
  position: relative;
  img {
    height: 375px;
  }
  .swiper-play {
    position: absolute;
    left: 160px;
    top: 160px;
    width: 55px;
    height: 55px;
    border-radius: 27.5px;
    background-color: rgba($color: #000, $alpha: 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #video {
    width: 375px;
    height: 375px;
    background-color: #000;
  }
}
.buy-area {
  background: #fff;
  .price-wrap {
    padding: 12px 18px 0;
    .price {
      font-size: 16px;
      color: #ff0069;
      .price-num {
        font-size: 30px;
      }
    }
  }
  .goods-name {
    padding: 12px 18px;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    min-height: 24px;
    .tag {
      padding: 2px 3px 0;
      font-size: 10px;
      line-height: 10px;
      font-weight: normal;
      color: #fff;
      margin-right: 2px;
      border-radius: 2px;
      background: rgb(240,123,210);
      background: linear-gradient(98deg, rgba(240,123,210,1) 0%, rgba(255,0,105,1) 100%);
    }
  }
  .goods-desc {
    padding: 0 18px;
    font-size: 13px;
    color: #666;
    overflow: hidden;
    padding-bottom: 8px;
    .stock {
      float: right;
    }
  }
}
.goods-section {
  position: relative;
  margin-top: 12px;
  padding: 0 18px;
  border-radius: 12px;
  background-color: #fff;
  overflow: hidden;
  .goods-section-title {
    position: relative;
    height: 45px;
    line-height: 45px;
    color: #262626;
    padding-left: 8px;
    margin-top: 3px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 15px;
      background-image: linear-gradient(180deg,#ff0069,#fad1cb);
    }
    h3 {
      font-size: 15px;
      font-weight: bold;
    }
    .count {
      font-size: 12px;
      position: absolute;
      top: 3px;
      left: 45px;
      height: 45px;
      line-height: 45px;
    }
  }
  .goods-discount {
    position: relative;
    padding: 18px 20px 5px 38px;
    color: #333;
    word-break: break-word;
    overflow: hidden;
    font-size: 13px;
    line-height: 18px;
    .title {
      position: absolute;
      left: 0;
      top: 18px;
      color: #262626;
      font-weight: 700;
    }
    .content {
      .content-item {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        font-size: 13px;
        span:first-child {
          font-size: 12px;
          line-height: 14px;
          height: 14px;
          color: #ff0069;
          border: 1px solid #ff0069;
          margin-right: 5px;
        }
      }
    }
  }
  .goods-serve {
    padding: 9px 18px;
    margin: 0 -18px;
    background-color: #fafafa;
    .detail-serve {
      position: relative;
      color: #262626;
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      .detail-serve-item {
        padding: 3px 12px 3px 0;
        display: inline-block;
        line-height: 1;
        color: #8c8c8c;
        i {
          margin-right: 3px;
          font-weight: bold;
          font-size: 13px;
        }
      }
    }
  }
  .tab-subpage {
    padding-bottom: 20px;
    .subpage-tit {
      text-align: center;
      font-size: 12px;
      margin: 12px 0 8px;
      color: #666;
    }
    .goods-cert-item {
      margin: 5px 0;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .goods-intro-content {
    img {
      max-width: 100%;
      height: auto !important;
    }
  }
  .goods-recommend {
    ul {
      position: relative;
      overflow: hidden;
      li {
        position: relative;
        list-style-type: none;
        float: left;
        width: 32.16%;
        margin-top: 5px;
      }
      li:not(:nth-child(3n)) {
        margin-right: 1.76%;
      }
    }
    .rc-item {
      .img-wrap {
        position: relative;
        width: 100%;
        padding-top: 100%;
        border-radius: 6px;
        overflow: hidden;
        img {
          position: absolute;
          max-width: 100%;
          max-height: 100%;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          border-radius: 6px;
        }
      }
      .name {
        padding: 7px 0 0;
        height: 3em;
        line-height: 1.5em;
        color: #333;
        font-size: 12px;
        font-weight: 400;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .buy-wrap {
        position: relative;
        height: 26px;
        line-height: 26px;
        color: #ff0069;
        font-size: 12px;
        .num {
          font-size: 16px;
        }
      }
    }
  }
}
.product-params-table {
  width: 100%;
  margin-bottom: 10px;
  border-collapse: collapse;
  font-size: 12px;
  border-spacing: 0;
  line-height: 18px;
  color: #666;
  td {
    padding: 8px;
    border: 1px solid #dadada;
    text-align: left;
    &:first-child {
      width: 80px;
    }
    &:last-child {
      word-break: break-all;
    }
  }
}

.product .intention-content {
  padding: 0 16px 20px;
}

</style>