<template>
  <div class="goods-qst">
    <div class="goods-section-title">
      <h3>问答</h3>
      <span v-if="list.length > 0" class="more" @click="onLookMore">查看全部问答 ></span>
    </div>
    <ul v-if="list.length > 0" class="qst-list">
      <li v-for="item in list.slice(0, 3)" :key="item.questId" class="qst-list-item">
        <p class="title">{{ item.questionTitle }}</p>
        <span class="asw-count">共{{ item.helperList.length || 0 }}个回答</span>
      </li>
    </ul>
    <div v-if="list.length == 0" class="empty">
      <p>心中疑惑就问买过此商品的朋友吧</p>
      <van-button type="info" size="small" round class="ask_btn" @click="onAsk">我要提问</van-button>
    </div>
  </div>
</template>

<script>
import Fetch from '@/common/fetch';
import { GetAskAnswerList } from '@/common/interface';

export default {
  name: 'GoodsQuestion',
  props: {
    productInfo: Object
  },
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.fetchList();
  },
  methods: {
    fetchList() {
      const params = {
        productId: this.productInfo.productId,
        productType: this.productInfo.productType,
        pageNum: 1,
        pageSize: 10,
      };
      Fetch.post(GetAskAnswerList, params).then((res) => {
        this.list = res.list || [];
      }).catch(() => {
      });
    },

    onLookMore() {
      this.$router.push('/faqs/list?id=' + this.productInfo.productId);
    },

    onAsk() {
      this.$router.push('/faqs/ask?id=' + this.productInfo.productId);
    },
  },
}
</script>

<style lang="scss">
.goods-qst {
  .qst-list {
    font-size: 12px;
    padding: 6px 0 18px;
    .qst-list-item {
      position: relative;
      margin-bottom: 18px;
      .title {
        padding-right: 80px;
        color: #333;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &::before {
          content: "问";
          display: inline-block;
          margin: -1px 5px 0 0;
          width: 14px;
          height: 14px;
          line-height: 14px;
          border-radius: 2px;
          background-color: #ff9600;
          color: #fff;
          font-size: 10px;
          vertical-align: middle;
          text-align: center;
        }
      }
      .asw-count {
        position: absolute;
        top: 0;
        right: 0;
        color: #999;
        font-size: 12px;
      }
    }
  }
  .empty {
    font-size: 15px;
    text-align: center;
    padding: 30px 0;
    p {
      margin-bottom: 10px;
    }
  }
}
.goods-section-title {
  position: relative;
  .more {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 13px;
  }
}
</style>